<script setup lang="ts">
const colorMode = useColorMode()

const light = 'mdi:white-balance-sunny'
const dark = 'lucide:moon'
const system = 'mdi:theme-light-dark'

const icon = computed(() => {
  switch (colorMode.preference) {
    case 'light':
      return light
    case 'dark':
      return dark
    default:
      return system
  }
})
</script>

<template>
  <UiDropdownMenu>
    <UiDropdownMenuTrigger as-child>
      <UiButton
        variant="ghost"
        size="icon"
      >
        <SmartIcon
          :name="icon"
          class="size-4"
          :size="18"
        />
        <span class="sr-only">Toggle theme</span>
      </UiButton>
    </UiDropdownMenuTrigger>
    <UiDropdownMenuContent>
      <UiDropdownMenuLabel>
        Set Color Mode
      </UiDropdownMenuLabel>
      <UiDropdownMenuItem @click="colorMode.preference = 'light'">
        <SmartIcon
          :name="light"
          class="mr-2 size-4"
        />
        <span>Light</span>
      </UiDropdownMenuItem>
      <UiDropdownMenuItem @click="colorMode.preference = 'dark'">
        <SmartIcon
          :name="dark"
          class="mr-2 size-4"
        />
        <span>Dark</span>
      </UiDropdownMenuItem>
      <UiDropdownMenuItem @click="colorMode.preference = 'system'">
        <SmartIcon
          :name="system"
          class="mr-2 size-4"
        />
        <span>System</span>
      </UiDropdownMenuItem>
    </UiDropdownMenuContent>
  </UiDropdownMenu>
</template>
